import React from 'react';
import PricingCard from './PricingCard';  // Assuming PricingCard is in the same folder
import './ProductGrid.css';  // CSS for the grid layout

const ProductGrid = ({ type, products }) => {

    return (
        <div className="product-grid">
            {products.map(product => (
                <PricingCard
                    type={type}
                    product={product}
                />
            ))}
        </div>
    )
}

export default ProductGrid;
