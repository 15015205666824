import React, { useState } from 'react';
import Turnstile from 'react-turnstile';

const ContactUs = () => {
    const [turnstileToken, setTurnstileToken] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Ensure there's a valid Turnstile token
        if (!turnstileToken) {
            alert('Please complete the CAPTCHA.');
            return;
        }

        // Send the form data to the server
        try {
            fetch('https://api.corehost.ing/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, message, turnstileToken }),
            });
        } catch (error) {
            console.error('Error:', error);
        } finally {
            alert('Your message has been sent! We will get back to you as soon as possible.');
        }

        // Clear the form and Turnstile token
        setName('');
        setEmail('');
        setMessage('');
        setTurnstileToken(null);
    };

    const isFormValid = name && email && message && turnstileToken;

    return (
        <div className="contact-us" id="contact">
            <h2>Contact Us</h2>
            <p>Get in touch with us to learn more about our services.</p>
            <form onSubmit={handleSubmit}>
                <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    name="message"
                    id="message"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                {/* Turnstile CAPTCHA */}
                <Turnstile
                    sitekey="0x4AAAAAAAzJSCPQLAznBHpM"
                    onSuccess={(token) => setTurnstileToken(token)}
                    onError={() => setTurnstileToken(null)}
                    onExpire={() => setTurnstileToken(null)}
                />

                <button type="submit" disabled={!isFormValid}>Send</button>
            </form>
        </div>
    );
}

export default ContactUs;
