const Footer = () => {
    let year = new Date().getFullYear();

    return (
        <footer>
        {/* Copyright */}
        <div className="cc">
            <p>© {year} CoreHosting. All Rights Reserved.</p>
        </div>
        <div className="tri-col">
            <div className="tri-col-item">
                <h4>Company</h4>
                <ul>
                    <li><a href="#about">About</a></li>
                    <li><a href="#team">Team</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
            <div className="tri-col-item">
                <h4>Services</h4>
                <ul>
                    <li><a href="#web">Web Hosting</a></li>
                    <li><a href="#vps">VPS Hosting</a></li>
                    <li><a href="#rust">Rust Hosting</a></li>
                    <li><a href="#minecraft">Minecraft Hosting</a></li>
                    <li><a href="#discord-bot">Discord Bot Hosting</a></li>
                </ul>
            </div>
            <div className="tri-col-item">
                <h4>Legal</h4>
                <ul>
                    <li><a href="https://billing.corehost.ing/ToS141124.html">Terms</a></li>
                    <li><a href="https://billing.corehost.ing/Privacy151124.html">Privacy</a></li>
                    <li><a href="https://billing.corehost.ing/Fairuse151124.html">Fair use</a></li>
                </ul>
            </div>
        </div>
        </footer>
    );
    }

export default Footer;
