import React from "react";
import "./App.css";
import Background from "./components/Background";
import HeroSection from "./components/HeroSection";
import FontAwesome from "react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import NavBar from "./components/NavBar";
import ServiceBuilder from "./components/ServiceBuilder";
import Team from "./components/Team";
import AboutUs from "./sections/AboutUs";
import Footer from "./components/Footer";
import PricingCard from "./sections/PricingCard";
import ProductGrid from "./sections/ProductGrid";
import ContactUs from "./sections/ContactUs";

function App() {

  // Define the product data for each service
  const vpsProducts = [
    {
      id: 1,
      price: "£4.99",
      title: "Pico",
      cpu: 2,
      ram: 4,
      disk: 20,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/pico"
    },
    {
      id: 2,
      price: "£6.99",
      title: "Nano",
      cpu: 2,
      ram: 6,
      disk: 40,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/nano"
    },
    {
      id: 3,
      price: "£8.99",
      title: "Micro",
      cpu: 4,
      ram: 8,
      disk: 60,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/micro"
    },
    {
      id: 4,
      price: "£16.99",
      title: "Boost",
      cpu: 6,
      ram: 16,
      disk: 80,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/boost"
    },
    {
      id: 5,
      price: "£24.99",
      title: "Pro",
      cpu: 8,
      ram: 24,
      disk: 100,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/pro"
    },
    {
      id: 6,
      price: "£32.99",
      title: "Elite",
      cpu: 10,
      ram: 32,
      disk: 120,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/elite"
    },
    {
      id: 7,
      price: "£52.99",
      title: "Quantum",
      cpu: 14,
      ram: 48,
      disk: 160,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/quantum"
    },
    {
      id: 8,
      price: "£72.99",
      title: "Infinity",
      cpu: 16,
      ram: 64,
      disk: 200,
      network: "1Gbps Unmetered*",
      protection: 'CosmicGuard DDoS',
      support: '24/7 365',
      buy_now: "https://billing.corehost.ing/store/vps/infinity"
    },
  ];

  const rustGameServers = [
    {
      id: 1,
      price: "£9.50",
      title: "Revolver",
      cpu: 0.75,
      ram: 8,
      disk: 10,
      backups: 1,
      databases: 1,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/revolver"
    },
    {
      id: 2,
      price: "£12.50",
      title: "Python",
      cpu: 1,
      ram: 10,
      disk: 20,
      backups: 1,
      databases: 1,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/python"
    },
    {
      id: 3,
      price: "£15.50",
      title: "Thompson",
      cpu: 1.25,
      ram: 12,
      disk: 30,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/thompson"
    },
    {
      id: 4,
      price: "£23.50",
      title: "MP5",
      cpu: 1.5,
      ram: 16,
      disk: 40,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/mp5"
    },
    {
      id: 5,
      price: "£29.50",
      title: "AK47",
      cpu: 2,
      ram: 20,
      disk: 50,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/ak47"
    },
    {
      id: 6,
      price: "£35.50",
      title: "M249",
      cpu: 2.5,
      ram: 24,
      disk: 50,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/game-servers/m249"
    }
  ];

  const minecraftGameServers = [
    {
      id: 1,
      price: "£2.00",
      title: "Beginner",
      cpu: 1,
      ram: 2,
      disk: 3,
      backups: 1,
      databases: 1,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/beginner"
    },
    {
      id: 2,
      price: "£4.00",
      title: "Explorer",
      cpu: 1,
      ram: 4,
      disk: 5,
      backups: 1,
      databases: 1,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/explorer"
    },
    {
      id: 3,
      price: "£6.00",
      title: "Adventurer",
      cpu: 2,
      ram: 6,
      disk: 8,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/adventurer"
    },
    {
      id: 4,
      price: "£8.00",
      title: "Master",
      cpu: 2,
      ram: 8,
      disk: 10,
      backups: 2,
      databases: 2,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/master"
    },
    {
      id: 5,
      price: "£16.00",
      title: "Champion",
      cpu: 4,
      ram: 16,
      disk: 20,
      backups: 3,
      databases: 3,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/champion"
    },
    {
      id: 6,
      price: "£24.00",
      title: "Legend",
      cpu: 4,
      ram: 24,
      disk: 25,
      backups: 4,
      databases: 4,
      protection: 'A2S + CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/minecraft-servers/legend"
    }
  ];

  const discordBotHosting = [
    {
      id: 1,
      price: "£1.99",
      title: "Budget",
      cpu: 0.25,
      ram: 0.5,
      disk: 4,
      backups: 1,
      databases: 1,
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/discord-bot-hosting/budget"
    },
    {
      id: 2,
      price: "£2.99",
      title: "Performance",
      cpu: 0.5,
      ram: 1,
      disk: 8,
      backups: 2,
      databases: 2,
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/discord-bot-hosting/performance"
    },
    {
      id: 3,
      price: "£4.99",
      title: "Performance +",
      cpu: 1,
      ram: 2,
      disk: 16,
      backups: 2,
      databases: 3,
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/discord-bot-hosting/performance-1"
    }
  ];

  const webHostingProducts = [
    {
      id: 1,
      price: "£1.00",
      title: "Starter",
      disk: 10,
      domain: 1,
      ssl: "Included",
      databases: 1,
      network: "1Gbps",
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/website-hosting/starter"
    },
    {
      id: 2,
      price: "£3.99",
      title: "Basic",
      disk: 25,
      domain: 5,
      ssl: "Included",
      databases: 5,
      network: "1Gbps",
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/website-hosting/basic"
    },
    {
      id: 3,
      price: "£7.99",
      title: "Pro",
      disk: 50,
      domain: 10,
      ssl: "Included",
      databases: 10,
      network: "1Gbps",
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/website-hosting/pro"
    },
    {
      id: 4,
      price: "£11.99",
      title: "Premium",
      disk: 75,
      domain: 25,
      ssl: "Included",
      databases: 25,
      network: "1Gbps",
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/website-hosting/premium"
    },
    {
      id: 5,
      price: "£19.99",
      title: "Enterprise",
      disk: 100,
      domain: "Unlimited",
      ssl: "Included",
      databases: "Unlimited",
      network: "1Gbps",
      protection: 'CosmicGuard DDoS',
      buy_now: "https://billing.corehost.ing/store/website-hosting/enterprise"
    }
  ];

  



  return (
    <div className="App">
      <div className="top-page" id='toppage'></div>

      <NavBar />
      <Background />
      <HeroSection />

      <div className="page-content" id='page-content'>
        <AboutUs />
        <div className="spacer" />
        <Team />
        <div className="spacer" />
        <div id={'services'} />
        <h1 className="txt-accent product-grid-header" style={{ textAlign: 'center', fontSize: '3rem' }} id={'vps'}>VPS Hosting</h1>
        <ProductGrid type={'vps'} products={vpsProducts} />
        <div className="spacer" />
        <h1 className="txt-accent product-grid-header" style={{ textAlign: 'center', fontSize: '3rem' }} id={'rust'}>Rust Hosting</h1>
        <ProductGrid type={'game'} products={rustGameServers} />
        <div className="spacer" />
        <h1 className="txt-accent product-grid-header" style={{ textAlign: 'center', fontSize: '3rem' }} id={'minecraft'}>Minecraft Hosting</h1>
        <ProductGrid type={'game'} products={minecraftGameServers} />
        <div className="spacer" />
        <h1 className="txt-accent product-grid-header" style={{ textAlign: 'center', fontSize: '3rem' }} id={'web'}>Web Hosting</h1>
        <ProductGrid type={'web'} products={webHostingProducts} />
        <div className="spacer" />
        <h1 className="txt-accent product-grid-header" style={{ textAlign: 'center', fontSize: '3rem'}} id={'discord-bot'}>Discord Bot Hosting</h1>
        <ProductGrid  type={'game'} products={discordBotHosting} />
        <div className="spacer" />
        <ContactUs />
        <div className="spacer" />
        <Footer />
      </div>
    </div>
  );
}

export default App;
