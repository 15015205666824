import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faMemory, faHdd, faDatabase, faCloud, faShieldAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './PricingCard.css';  // Assuming you create a CSS file for styles

const PricingCard = ({ type, product, index }) => {

    // Umami tracking function
    const trackClick = (productTitle) => {
        if (window.umami) {
            console.log(`Buy Now - ${productTitle}`); // Log to console for debugging
            window.umami.track(`Buy Now - ${productTitle}`);
        }
    };

    if (type === 'game') {
        const { title, price, cpu, ram, disk, backups, databases, protection, buy_now } = product;
        return (
            <div className="pricing-card">
                <h2>{title || "N/A"}</h2> {/* If title is undefined, fallback to "N/A" */}
                <p className="price">{price || "N/A"}<span>/Month</span></p> {/* Fallback if price is undefined */}
                <div className="features">
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faMicrochip} size="2x" />
                        </div>
                        <p>{cpu || "0"} CPU</p> {/* Fallback if cpu is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faMemory} size="2x" />
                        </div>
                        {/* Check if ram is < 1GB, then display in MB */}
                        <p>{ram < 1 ? `${ram * 1024}MB` : `${ram}GB`} RAM</p> {/* Fallback if ram is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faHdd} size="2x" />
                        </div>
                        <p>{disk || "0"}GB Disk</p> {/* Fallback if disk is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{backups || "0"} Backup(s)</p> {/* Fallback if backups are undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faDatabase} size="2x" />
                        </div>
                        <p>{databases || "0"} Database(s)</p> {/* Fallback if databases are undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faShieldAlt} size="2x" />
                        </div>
                        <p>{protection || "0"} Protection</p> {/* Fallback if protection is undefined */}
                    </div>
                </div>
                <a
                    href={buy_now || "#"}
                    className="buy-now"
                    id={`buynow-${index}`} // Dynamic ID
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackClick(title)} // Umami tracking on click
                >
                    Buy Now
                </a>
            </div>
        );
    } else if (type === 'web') {
        const { title, price, disk, domain, ssl, databases, network, protection, buy_now } = product;
        return (
            <div className="pricing-card">
                <h2>{title || "N/A"}</h2> {/* If title is undefined, fallback to "N/A" */}
                <p className="price">{price || "N/A"}<span>/Month</span></p> {/* Fallback if price is undefined */}
                <div className="features">
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faHdd} size="2x" />
                        </div>
                        <p>{disk || "0"}GB Disk</p> {/* Fallback if disk is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{domain || "0"} Domain(s)</p> {/* Fallback if domain is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{ssl ? "SSL Included" : "No SSL"}</p> {/* Fallback if ssl is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faDatabase} size="2x" />
                        </div>
                        <p>{databases || "0"} Database(s)</p> {/* Fallback if databases are undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{network || "0"} Network</p> {/* Fallback if network is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faShieldAlt} size="2x" />
                        </div>
                        <p>{protection || "0"} Protection</p> {/* Fallback if protection is undefined */}
                    </div>
                </div>
                <a
                    href={buy_now || "#"}
                    className="buy-now"
                    id={`buynow-${index}`} // Dynamic ID
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackClick(title)} // Umami tracking on click
                >
                    Buy Now
                </a>
            </div>
        );
    } else if (type === 'vps') {
        const { title, price, cpu, ram, disk, network, protection, support, buy_now } = product;
        return (
            <div className="pricing-card">
                <h2>{title || "N/A"}</h2> {/* If title is undefined, fallback to "N/A" */}
                <p className="price">{price || "N/A"}<span>/Month</span></p> {/* Fallback if price is undefined */}
                <div className="features">
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faMicrochip} size="2x" />
                        </div>
                        <p>{cpu || "0"} CPU</p> {/* Fallback if cpu is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faMemory} size="2x" />
                        </div>
                        <p>{ram < 1 ? `${ram * 1024}MB` : `${ram}GB`} RAM</p> {/* Fallback if ram is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faHdd} size="2x" />
                        </div>
                        <p>{disk || "0"}GB Disk</p> {/* Fallback if disk is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{network || "0"} Network</p> {/* Fallback if network is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCloud} size="2x" />
                        </div>
                        <p>{protection || "0"} Protection</p> {/* Fallback if protection is undefined */}
                    </div>
                    <div className="feature-item">
                        <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                        </div>
                        <p>{support || "0"} Support</p> {/* Fallback if support is undefined */}
                    </div>
                </div>
                <a
                    href={buy_now || "#"}
                    className="buy-now"
                    id={`buynow-${index}`} // Dynamic ID
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackClick(title)} // Umami tracking on click
                >
                    Buy Now
                </a>
            </div>
        );
    }
}

export default PricingCard;
