const Team = () => {
    return (
        <>

            <div className="Our-Team">
                <div className="spacer"/>
                <h2 className='txt-accent'>Meet the Team</h2>
            </div>

            <div className='content-team'>
                
                <div className="the-team" id="team">

                    <div className="the-team">
                        <div className="team-member">
                            <img src="/featherfall.png" alt="Featherfall" />
                            <h3 className='txt-accent'>Featherfall</h3>
                            <p className='txt-primary'><strong>Director</strong></p>
                            <div className='about'>
                                <blockquote>
                                    "I have been working in customer service and public relations for 3 years, making sure people get the best out of their services, and working with clients to improve our products is what I do best"
                                </blockquote>
                            </div>
                        </div>
                        <div className="team-member">
                            <img src="/jake.png" alt="Jake" />
                            <h3 className='txt-accent'>Jake</h3>
                            <p className='txt-primary'><strong>Director | Developer</strong></p>
                            <div className='about'>
                                <blockquote>
                                    "With A Background in cyber security and software engineering, I've always been passionate about technology and I am always looking for ways to improve our services"
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Team