import React, { useState } from 'react';

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <div className="navbar">
            <div className="logo">
                <img src="/logo-color.png" alt="logo" />
                <span>
                    <h1 className="txt-primary">CoreHost</h1>
                    <h1 className="txt-accent">.ing</h1>
                </span>
            </div>

            {/* Desktop Navigation */}
            <div className="nav-items">
                <a href="#team">Team</a>
                <a href="#services">Services</a>
                <a href="#contact">Contact</a>
            </div>

            {/* Burger Menu Icon */}
            <div className="burger-menu" onClick={toggleMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>

            {/* Mobile Navigation */}
            <div className={`nav-items-mobile ${menuActive ? 'nav-active' : ''}`}>
                <a href="#services" onClick={toggleMenu}>Services</a>
                <a href="#about" onClick={toggleMenu}>About</a>
                <a href="#team" onClick={toggleMenu}>Team</a>
                <a href="#contact" onClick={toggleMenu}>Contact</a>
            </div>
        </div>
    );
};

export default Navbar;
