const HeroSection = () => {
    return (
        <>
            <div className="logo-container" id='logo-container'>
                <img src="/logo-color.png" alt="logo" />
                <span>
                    <h1>CoreHost</h1>
                    <h1 className="txt-accent">.ing</h1>
                </span>
                <h3 className="txt-primary" style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '10px',
                    fontSize: '1.5rem'

                }}>Where Hosting Meets The Next Generation Of Technology</h3>
                <a href="#services" className="btn-primary">Get Started</a>
            </div>
            
            <div className="back-to-top">
                <a href="#toppage">
                    <img src="/back-to-top.png" alt="arrow-down" />
                </a>
            </div>
            </>
    )

}

export default HeroSection;