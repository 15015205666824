const AboutUs = () => {
    return (
        <div className="about-us">
        <h1 className="header txt-accent">About Us</h1>
        <p className="txt-primary">
            CoreHost.ing was founded in 2024 with the merger of two respectable hosting companies, FastVPS.Systems & ScarceHost.<br/><br/> Founded On The Principles Of Providing The Best Hosting Services At The Best Prices, We Value Our Customers More Than We Value Profit. We Are A Team Of Developers, Engineers, And Technicians Who Are Passionate About Technology And Are Always Looking For Ways To Improve Our Services, Our Dedicated Team work endlessly to ensure a smooth and efficient service for all clients, No matter how big or small. We offer industry Leading hardware in combination with bleeding edge ddos protection. Combined with our 24/7 support, we are the perfect choice for all your hosting needs.

        </p>
        </div>
    );
    }

export default AboutUs;